import React from "react";
import { Link } from "gatsby-theme-material-ui";
import { Typography } from "@material-ui/core";

import { Layout, Meta, Section } from "components";

/**
 * Render 404 page.
 * @returns {JSX.Element} component
 */
export default function Page() {
  return (
    <Layout hero>
      <Meta
        title="404"
        description="The page you were looking for cannot be found."
      />
      <Section hero>
        <Typography variant="h2">
          The page you were looking for cannot be found.
        </Typography>
        <Link to="/" variant="h3">
          Go to the homepage.
        </Link>
      </Section>
    </Layout>
  );
}
